import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Footer } from 'common';
import 'assets/scss/_custom.scss';
import userIcon from 'assets/images/dashboard.png';
import { settingData } from 'store';
const LogoUrl = () => {
  const getSettingData = useSelector(settingData);
  return (
    <LazyLoadImage
      key={
        getSettingData.home_page_general_login_logo
          ? getSettingData.home_page_general_login_logo
          : userIcon
      }
      placeholderSrc={userIcon}
      src={
        getSettingData.home_page_general_login_logo
          ? getSettingData.home_page_general_login_logo
          : userIcon
      }
      alt="boilerplate-logo"
      className="nav-brand"
    />
  );
};
const PublicLayout = (props) => {
  useEffect(() => {
    document.body.classList.add('bg-box');
  }, []);
  return (
    <div {...props} className="auth-pages">
      {props.children}
      <Footer />
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export { PublicLayout, LogoUrl };
