import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'assets/scss/page/_notifications.scss';
import { useGetNotifications } from 'hooks';
import { setNotifications, addNotification } from 'store';

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications?.notifications);

  const { refetch } = useGetNotifications((res) => {
    dispatch(setNotifications(res.data));
  });

  useEffect(() => {
    refetch();

    const handlePushNotification = (event) => {
      const { title, message, description, redirection_url } = event.data;
      const newNotification = { title, message, description, redirection_url };
      dispatch(addNotification(newNotification));
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handlePushNotification);
    }

    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', handlePushNotification);
      }
    };
  }, [dispatch, refetch]);

  const handleDeleteAll = () => {
    dispatch(setNotifications([]));
  };

  const handleReadAll = () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      is_read: '1',
    }));
    dispatch(setNotifications(updatedNotifications));
  };

  return (
    <section id="content-wrapper">
      <div className="notification-list">
        <div className="my-3 text-end">
          {notifications?.length > 0 && (
            <>
              <button type="button" className="btn btn-primary me-3" onClick={handleDeleteAll}>
                Clear All
              </button>
              <button type="button" className="btn btn-primary me-3" onClick={handleReadAll}>
                Mark All as Read
              </button>
            </>
          )}
        </div>
        <div className="my-3">
          <h2>Notifications</h2>
        </div>
        {notifications?.length === 0 ? (
          <div className="mt-3 notification-card">
            <div className="notification-card-body text-center">
              <h4>No notifications available.</h4>
            </div>
          </div>
        ) : (
          notifications?.map((notification, index) => (
            <div
              key={index}
              className={`mt-3 notification-card ${
                notification?.is_read === 0 ? 'unread-block' : ''
              }`}>
              <div className="notification-card-body">
                <h5>
                  <b>{notification.title}</b>
                </h5>
                <p>{notification.message}</p>
                {notification.description && <p>{notification.description}</p>}
                {notification.redirection_url && (
                  <p>
                    <a href={notification.redirection_url} className="text-decoration-none">
                      View Details
                    </a>
                  </p>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default Notifications;
