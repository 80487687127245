import { createSlice } from '@reduxjs/toolkit';

/**
 * Slice for Loader
 */
const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    count: 0,
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      state.count = action?.payload?.length;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
      state.count += 1;
    },
  },
});
const { setNotifications, addNotification } = notificationSlice.actions;
export { notificationSlice, setNotifications, addNotification };
export default notificationSlice.reducer;
