import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT } from 'config';
const ListFaqCategoryPage = React.lazy(() => import('./ListFaqCategoryPage'));
const AddFaqCategoryPage = React.lazy(() => import('./AddFaqCategoryPage'));
const EditFaqCategoryPage = React.lazy(() => import('./EditFaqCategoryPage'));

const FaqCategoryRoute = ({ t }) => {
  return (
    <>
      <Routes>
        <Route index exact path={LIST} element={<ListFaqCategoryPage t={t} />} />
        <Route exact path={ADD} element={<AddFaqCategoryPage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditFaqCategoryPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
FaqCategoryRoute.propTypes = {
  t: PropTypes.func,
};
export default FaqCategoryRoute;
