import React, { useContext, createContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useGenerateRefreshToken } from 'hooks';
import { t } from 'i18next';

import { logoutSuccess, updateToken, loggedUser } from 'store';
import { TNButton, TimeCounterDown } from 'common';
import { LOGIN } from 'config';
const UserActivity = createContext({ clearAllUserData: () => {}, LoggedInUser: {} });

const UserActivityProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [state, setState] = useState('Active');
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const checkLoggedInUser = useSelector(loggedUser);
  const LoggedInUser = checkLoggedInUser.user || {};

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  const decodedJwt = parseJwt(checkLoggedInUser.accessToken || '');
  const created_time = moment(decodedJwt ? decodedJwt.created_at || '' : '');
  let current_time = moment();
  let duration = moment.duration(current_time.diff(created_time));
  let minutes = parseInt(duration.asMinutes(), 10) % 60;
  if (!decodedJwt) {
    minutes = 0;
  }

  const timeout = 660_000;
  const promptBeforeIdle = 60_000;

  const onIdle = () => {
    dispatch(logoutSuccess());
    localStorage.loginActivityError = t('page.admin_auto_logout_by_in_activity');
    toast.error(t('page.admin_auto_logout_by_in_activity'));
    navigation(LOGIN);
    setShow(false);
  };

  const onActive = () => {
    setState('Active');
    setShow(false);
  };
  const onPrompt = () => {
    if (minutes >= 60) {
      dispatch(logoutSuccess());
      localStorage.loginActivityError = t('page.admin_auto_logout_by_in_timeout');
      toast.error(t('page.admin_auto_logout_by_in_timeout'));
      setShow(false);
      navigation(LOGIN);
    } else {
      setShow(true);
    }
    setState('Prompt');
  };
  const onAction = () => {
    current_time = moment();
    duration = moment.duration(current_time.diff(created_time));
    minutes = parseInt(duration.asMinutes(), 10) % 60;
    if (minutes >= 58) {
      setState('Action');
      setShow(true);
    }
  };
  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle: 600,
    disabled: !decodedJwt ? true : false,
  });

  const { mutate: doTokenData } = useGenerateRefreshToken((res) => {
    dispatch(updateToken(res.data.refreshToken));
  });

  if (minutes >= 60) {
    console.log('else timeout', minutes, history, 'history');
    dispatch(logoutSuccess());
    toast.error(t('page.admin_auto_logout_by_in_timeout'));
    localStorage.loginActivityError = t('page.admin_auto_logout_by_in_timeout');
    navigation(LOGIN);
  }
  return (
    <UserActivity.Provider value={{ minutes, LoggedInUser }}>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="mt-5"
        centered>
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter" className="ps-2">
            {state === 'Action'
              ? t('page.admin_auto_logout_session_timeout_title_text')
              : t('page.admin_auto_logout_title_text')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="fw-light">
            {state === 'Action'
              ? t('page.admin_auto_logout_session_timeout_description_text')
              : t('page.admin_auto_logout_description_text')}
          </p>
          {t('page.admin_auto_logout_countdown')}
          <TimeCounterDown
            start_date={created_time.format('YYYY-MM-DD hh:mm:ss')}
            end_date={current_time.format('YYYY-MM-DD hh:mm:ss')}
            endEvent={() => {
              dispatch(logoutSuccess());
              navigation(LOGIN);
            }}
          />
          <div className="inner-page-button button-auto-logout">
            <TNButton
              onClick={() => {
                dispatch(logoutSuccess());
                navigation(LOGIN);
                setShow(false);
              }}
              className="button-rounded-logout cancel-button settings-btn mx-2">
              {t('page.admin_auto_logout_popup_logout_button')}
            </TNButton>
            <TNButton
              onClick={() => {
                if (minutes >= 58) {
                  doTokenData();
                }
                activate();
                setShow(false);
              }}
              className="button-rounded checkout-submit-button settings-btn section-button mx-2"
              type="button">
              {`${t('page.admin_auto_logout_popup_stay_log_in_button')}`}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
      {children}
    </UserActivity.Provider>
  );
};
UserActivityProvider.propTypes = {
  children: PropTypes.any,
};
const UserActivityContext = () => {
  return useContext(UserActivity);
};
export { UserActivityProvider, UserActivityContext };
