import client from 'libs/HttpClient';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBZKTWuidUtpxXMQqP2bIIN4pbMQbKPFnY',
  authDomain: 'nodereact-boilerplate.firebaseapp.com',
  projectId: 'nodereact-boilerplate',
  storageBucket: 'nodereact-boilerplate.appspot.com',
  messagingSenderId: '639866522270',
  appId: '1:639866522270:web:f0e46600f42e9c708c498f',
  measurementId: 'G-BGNF53VSJJ',
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
  if ('Notification' in window) {
    const hasAlertedUser = localStorage.getItem('hasAlertedUser');
    const permission = await Notification.requestPermission();
    if (permission === 'denied' && !hasAlertedUser) {
      alert('Please allow notifications');
      localStorage.setItem('hasAlertedUser', 'true');
    } else if (permission === 'granted') {
      generateToken();
    }
  }
};

const generateToken = async () => {
  const token = await getToken(messaging, {
    vapidKey:
      'BAresPoZbViXZ0WwpzNOd-uW8MXGk38Udr4n6e_Fyof6jvfr9D9SbwcfC0n16FF2CPOR23YUn0NuZET1Kw6aOGE',
  });
  if (token) {
    localStorage.fcmToken = token;
    await client.post(`/notifications/store`, { token: token });
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
