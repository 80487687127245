import { AdminAttributesService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetAttributesList = (params, onSuccess, onError) => {
  return useQuery(
    ['attributes_list', [params]],
    () => {
      return AdminAttributesService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddAttributes = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAttributesService.add, {
    onSuccess,
    onError,
  });
};
const useGetAttribute = (params, onSuccess, onError) => {
  return useQuery(
    ['attribute_detail', [params]],
    () => {
      return AdminAttributesService.get({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useUpdateAttributes = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAttributesService.edit, {
    onSuccess,
    onError,
  });
};
const useDeleteAttribute = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAttributesService.delete, {
    onSuccess,
    onError,
  });
};
const useAttributeStatusChange = (onSuccess, onError) => {
  return useMutation(AdminAttributesService.changeStatus, {
    onSuccess,
    onError,
  });
};
export {
  useGetAttributesList,
  useAddAttributes,
  useGetAttribute,
  useUpdateAttributes,
  useDeleteAttribute,
  useAttributeStatusChange,
};
