import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LIST, ADD, EDIT } from 'config';

const ListCmsPage = React.lazy(() => import('./ListCmsPage'));
const AddCmsPage = React.lazy(() => import('./AddCmsPage'));
const EditCmsPage = React.lazy(() => import('./EditCmsPage'));

const CmsRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<ListCmsPage t={t} />} />
      <Route exact path={ADD} element={<AddCmsPage t={t} />} />
      <Route exact path={EDIT + ':cms_id'} element={<EditCmsPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CmsRoutes.propTypes = {
  t: PropTypes.func,
};
export default CmsRoutes;
