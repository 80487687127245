import client from 'libs/HttpClient';

class AdminCategoryService {
  static getList({ params }) {
    return client.get(`category/list`, { params });
  }
  static add(request) {
    return client.post(`category/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getParentCategory(request) {
    return client.get(`category/parent`, request);
  }
  static view(params) {
    return client.get(`category/view`, { params });
  }
  static edit(request) {
    return client.put(`category/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static changeStatus(request) {
    return client.put(`category/status`, request);
  }
  static delete(params) {
    return client.delete(`category/delete`, { params });
  }
  static getSubCategory(params) {
    return client.get(`category/sub-category`, { params });
  }
}

export { AdminCategoryService };
