import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { useGetSettingData } from 'hooks';
import { addSetting, settingData } from 'store';
import { LIST, ADD, EDIT } from 'config';
const ListTeamMemberPage = React.lazy(() => import('./ListTeamMemberPage'));
const AddTeamMemberPage = React.lazy(() => import('./AddTeamMemberPage'));
const EditTeamMemberPage = React.lazy(() => import('./EditTeamMemberPage'));

const TeamMemberRoute = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: setting }) => {
    const dataStore = {
      dashboard_logo: setting.common_header_logo,
      front_logo: setting.common_login_logo,
      email_logo: setting?.common_email_header_logo,
      favicon_icon: setting?.common_favicon_icon,
      seo_title: setting.seo_title,
      seo_description: setting.seo_description,
      setting_get: false,
    };
    dispatch(addSetting(dataStore));
  });
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Routes>
        <Route index exact path={LIST} element={<ListTeamMemberPage t={t} />} />
        <Route exact path={ADD} element={<AddTeamMemberPage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditTeamMemberPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
TeamMemberRoute.propTypes = {
  t: PropTypes.func,
};
export default TeamMemberRoute;
