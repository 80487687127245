import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import HomeRoutes from './HomeSetting/HomeSettingRoutes';
import AboutRoutes from './AboutSetting/AboutSettingRoutes';
import ContactRoutes from './ContactUsSetting/ContactUsSettingRoutes';
import FaqRoutes from './FaqSetting/FaqSettingRoutes';
import GeneralSetting from './GeneralSettingPage';
import { GeneralMailNotificationsSettings } from './GeneralMailNotificationsPage';
import { SETTING, GENERAL, MAIL_SETTING, ABOUT, CONTACT, FAQ, HOME } from 'config';

const Loading = () => (
  <div className="text-center">
    <Spinner animation="border" />
  </div>
);

const SettingsRoutes = ({ t }) => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path={GENERAL} element={<GeneralSetting t={t} />} />
      <Route path={MAIL_SETTING} element={<GeneralMailNotificationsSettings t={t} />} />
      <Route path={HOME + '/*'} element={<HomeRoutes t={t} />} />
      <Route path={ABOUT + '/*'} element={<AboutRoutes t={t} />} />
      <Route path={CONTACT + '/*'} element={<ContactRoutes t={t} />} />
      <Route path={FAQ + '/*'} element={<FaqRoutes t={t} />} />
    </Routes>
  </Suspense>
);

export default SettingsRoutes;
