import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CONTACT_SETTING } from 'config';

const ContactUsSetting = React.lazy(() => import('./ContactUsSettingPage'));

const ContactRoutes = ({ t }) => (
  <Routes>
    <Route path={CONTACT_SETTING} element={<ContactUsSetting t={t} />} />
  </Routes>
);

export default ContactRoutes;
