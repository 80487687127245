import client from 'libs/HttpClient';

class TranslationService {
  static addTranslation(request) {
    return client.put('translation/add', request);
  }
  static updateTranslation(request) {
    return client.put(`translation/edit`, request);
  }
  static deleteTranslation(params) {
    return client.delete(`translation/delete/`, { params });
  }
  static listTranslation({ params }) {
    return client.get(`translation/list`, { params });
  }
  static viewTranslation(params) {
    return client.get(`translation/get`, { params });
  }
  static syncTranslation(request) {
    return client.put(`translation/sync-data`, request);
  }
}
export { TranslationService };
