import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'helpers';

const PhoneNumberInput = (props) => {
  return (
    <Form.Control
      {...props}
      type="text"
      onInput={(e) => {
        var value = e.target.value.replace(/\D/g, '');
        console.log('value: ', value);
        var formattedValue = '';
        if (value.length > 0) {
          formattedValue = '(' + value.substring(0, 3);
          if (value.length > 3) {
            formattedValue += ') ' + value.substring(3, 6);
            if (value.length > 6) {
              formattedValue += '-' + value.substring(6, 10);
            }
          }
        }
        console.log('formattedValue: ', formattedValue);
        e.target.value = formattedValue;
      }}
      value={formatPhoneNumber(props.value)}
    />
  );
};
PhoneNumberInput.propTypes = {
  value: PropTypes.any,
};
export { PhoneNumberInput };
