import React from 'react';
import { Route, Routes } from 'react-router-dom';

const HomeBannerSetting = React.lazy(() => import('./HomeBannerSettingPage'));
const HomeSeoSetting = React.lazy(() => import('./HomeSeoSettingPage'));
const HomeJobPostSetting = React.lazy(() => import('./HomeJobPostSettingPage'));
const HomeFooterSetting = React.lazy(() => import('./HomeFooterSettingPage'));
const HomeDownloadSetting = React.lazy(() => import('./HomeDownloadSettingPage'));

const HomeRoutes = ({ t }) => (
  <>
    <Routes>
      <Route path="/home-banner" element={<HomeBannerSetting t={t} />} />
      <Route path="/home-seo" element={<HomeSeoSetting t={t} />} />
      <Route path="/home-job-post" element={<HomeJobPostSetting t={t} />} />
      <Route path="/home-footer" element={<HomeFooterSetting t={t} />} />
      <Route path="/home-download" element={<HomeDownloadSetting t={t} />} />
    </Routes>
  </>
);

export default HomeRoutes;
