import * as Yup from 'yup';
const validationSchema = Yup.object({
  category_name: Yup.string()
    .required('Category name is required')
    .min(2, 'Category name must be at least 2 characters')
    .max(100, 'Category name cannot exceed 100 characters'),
  keyword: Yup.string()
    .required('Keyword is required')
    .min(2, 'Keyword must be at least 2 characters')
    .max(100, 'Keyword cannot exceed 100 characters'),
  sequence: Yup.string()
    .required('Sequence is required')
    .matches(/^[0-9]+$/, 'Sequence must be a number'),
  category_image: Yup.mixed().required('Category image is required'),

  status: Yup.string().required('Status is required'),
  parent_id: Yup.string().optional(),
});
export default validationSchema;
