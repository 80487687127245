import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, EDIT, VIEW } from 'config';
const ListUserPage = React.lazy(() => import('./ListUserPage'));
const EditUserPage = React.lazy(() => import('./EditUserPage'));
const ViewUserPage = React.lazy(() => import('./ViewUserPage'));

const UserRoutes = ({ t }) => {
  return (
    <>
      <Routes>
        <Route index exact path={LIST} element={<ListUserPage t={t} />} />
        <Route exact path={EDIT + ':user_id'} element={<EditUserPage t={t} />} />
        <Route exact path={VIEW + ':user_id'} element={<ViewUserPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
UserRoutes.propTypes = {
  t: PropTypes.func,
};
export default UserRoutes;
