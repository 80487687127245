import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LIST, CATEGORIES, ATTRIBUTES } from 'config';

const SidebarCategoriesLinks = (props) => {
  const { t } = props;
  const categoryClass = location.pathname.match(/^\/category/) ? 'active' : '';
  const attributesClass = location.pathname.match(/^\/attributes/) ? 'active' : '';
  return (
    <>
      <ul className={`submenu collapse ${props.category ? 'show' : ''}`}>
        <li>
          <Link
            className={categoryClass}
            to={CATEGORIES + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_category')}
          </Link>
        </li>

        <li>
          <Link
            className={attributesClass}
            to={ATTRIBUTES + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_attributes')}
          </Link>
        </li>
      </ul>
    </>
  );
};

export { SidebarCategoriesLinks };
