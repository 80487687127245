import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT } from 'config';
const ListFeaturePage = React.lazy(() => import('./ListFeaturePage'));
const AddFeaturePage = React.lazy(() => import('./AddFeaturePage'));
const EditFeaturePage = React.lazy(() => import('./EditFeaturePage'));

const FeatureRoute = ({ t }) => {
  return (
    <>
      <Routes>
        <Route index exact path={LIST} element={<ListFeaturePage t={t} />} />
        <Route exact path={ADD} element={<AddFeaturePage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditFeaturePage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
FeatureRoute.propTypes = {
  t: PropTypes.func,
};
export default FeatureRoute;
