import { UserMasterService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useUpdateUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.updateUser, {
    onSuccess,
    onError,
  });
};
const useUserDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.userDelete, {
    onSuccess,
    onError,
    retry: 0,
  });
};

const useListUsers = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['user-list', params], () => UserMasterService.listUser({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useViewUser = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery('user-view', () => UserMasterService.viewUser({ user_id }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useStatusChangeUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.statusChangeUser, {
    onSuccess,
    onError,
  });
};
const useStatusChangeAdmin = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.statusChangeAdmin, {
    onSuccess,
    onError,
  });
};

export {
  useUpdateUser,
  useUserDelete,
  useListUsers,
  useViewUser,
  useStatusChangeUser,
  useStatusChangeAdmin,
};
